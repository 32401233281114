@import "primereact/resources/themes/lara-light-indigo/theme.css";
@import "react-time-picker/dist/TimePicker.css";
@import "react-clock/dist/Clock.css";
@import "primeicons/primeicons.css";
@import "primereact/resources/primereact.css";
@import "primereact/resources/themes/lara-light-indigo/theme.css";


.stickybar {
  top: 0;
  position: sticky;
}
.p-calendar.p-component.p-inputwrapper .p-inputtext.p-component {
  background: #fcfbfb;
  border: 1px solid #f4f4f4;
  box-shadow: none;
  border-radius: 5px;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: #201f1d;
  padding-right: 0;
  max-width: 150px;
}
.p-calendar.p-component.p-inputwrapper input:focus {
  box-shadow: none;
}

.search-box-banner .group-img i {
  top: 17px;
}
.p-calendar.p-component.p-inputwrapper input::placeholder {
  font-size: 14px;
}
.p-datepicker-calendar-container table.p-datepicker-calendar {
  font-size: 14px;
}
div.p-datepicker table td.p-datepicker-today > span {
  background: #ffa633;
  color: #fff;
}
.p-calendar.p-component input {
  font-size: 14px;
}
.group-img div.ant-picker {
  height: 45px;
  padding: 0 10px 0 0;
}
.group-img .ant-picker .ant-picker-input input {
  text-overflow: unset;
  padding: 2px 13px 13px 36px;
}
.group-img .ant-picker .ant-picker-input {
  top: 10px;
}
.group-img .ant-picker .ant-picker-input input::placeholder {
  color: #787878;
}
.ant-picker-input span.ant-picker-suffix {
  display: none;
}
/* ant datatable design */
.ant-pagination .ant-pagination-item-active {
  border-color: #127384;
  border-radius: 10px;
  color: #ffffff;
  background-color: #127384;
  width: 40px;
  height: 35px;
}
/* Action filed style in user walletpage */
/* .dropdown-action .dropdown-toggle {
  width: 30px;
  height: 30px;
  font-size: 16px;
  color: #737373;
  border-radius: 5px;
  border: 1px solid #dbdbdb;
  background: #ffffff;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
} */
/* Antd table header  */
.ant-table-wrapper .ant-table-thead > tr > th,
:where(.css-dev-only-do-not-override-1rqnfsa).ant-table-wrapper
  .ant-table-thead
  > tr
  > td {
  color: #111;
  background: #f2f7f6 !important;
}
:where(.css-dev-only-do-not-override-1rqnfsa).ant-table-wrapper
  .ant-table-thead
  > tr
  > th,
:where(.css-dev-only-do-not-override-1rqnfsa).ant-table-wrapper
  .ant-table-thead
  > tr
  > td {
  position: relative;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 700 !important;
  text-align: start;
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  font-family: "Fira Sans", sans-serif !important;
}

/* datatable checkbox */
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #201f1d;
  border-color: #201f1d;
}

.ant-table-wrapper
  .ant-table-tbody
  .ant-table-row.ant-table-row-selected
  > .ant-table-cell {
  background: none !important;
}
.ant-table-wrapper table tr th.ant-table-selection-column,
:where(.css-dev-only-do-not-override-1rqnfsa).ant-table-wrapper
  table
  tr
  td.ant-table-selection-column,
:where(.css-dev-only-do-not-override-1rqnfsa).ant-table-wrapper
  .ant-table-selection-column {
  padding-inline-end: 20px;
  padding-inline-start: 19px;
  text-align: center;
}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-checkbox-wrapper:not(
    .ant-checkbox-wrapper-disabled
  ):hover
  .ant-checkbox-inner,
:where(.css-dev-only-do-not-override-1rqnfsa).ant-checkbox:not(
    .ant-checkbox-disabled
  ):hover
  .ant-checkbox-inner {
  border-color: #e3e6ea;
}
.p-datatable-table .p-datatable-thead tr th {
  background-color: #f2f7f6;
  color: #111;
  font-weight: 500;
  padding: 15px;
  font-size: 16px;
  font-family: "Fira Sans", sans-serif;
}
.dashboard-table .table tbody tr td {
  border-bottom: 1px solid #dbdbdb;
  font-size: 13px;
  padding: 10px 15px;
}
.dashboard-table .table td .table-avatar a {
  font-weight: 600;
}
.p-datatable .p-paginator:before {
  content: "Show";
  left: 15px;
  position: absolute;
  font-size: 14px;
  color: #111;
}
div.p-dropdown.p-component.p-inputwrapper.p-inputwrapper-filled {
  padding: 5px;
  width: auto;
  height: auto;
  border: 2px solid #dbdbdb;
  color: #737373;
  font-size: 16px;
  margin-left: 15px;
  align-items: center;
}
.p-datatable .p-dropdown {
  left: 40px;
  position: absolute;
}
.p-datatable .p-paginator:after {
  content: "Entries";
  left: 10%;
  position: absolute;
  font-size: 14px;
  color: #111;
}
.p-paginator-bottom.p-paginator.p-component {
  margin: 10px;
}
.table-responsive.dashboard-table .p-datatable button svg {
  height: 60px !important;
  color: #000 !important;
  width: 30px !important;
}
.p-paginator-page.p-paginator-element {
  border-radius: 10px;
  background-color: #127384;
  color: #ffffff;
  border-color: #127384;
}
/* .p-paginator-prev::before {
  content: "Prev";
  color: #2f2f2f;
} */
/* .p-paginator-next::after {
  content: "Next";
  color: #2f2f2f;
} */
/* .p-paginator-prev.p-paginator-element {
  position: absolute;
  right: 15%;
} */
/* span.p-paginator-pages {
  position: absolute;
  right: 10%;
} */
/* .p-paginator-next.p-paginator-element {
  position: absolute;
  right: 5%;
} */
.p-dropdown-trigger svg {
  width: 10px;
}
.p-dropdown span.p-dropdown-label.p-inputtext {
  font-size: 13px;
  padding: 0;
  margin: 0;
  align-items: center;
  text-align: center;
}
.p-dropdown-trigger {
  padding: 0 !important;
  width: 20px;
    justify-content: flex-end;
}
#tablefilter label::before {
  position: absolute;
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
    font-size: 15px;
    content: "\f002";
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: #737373;
}
#tablefilter label {
  position: relative;
}
#tablefilter input:not(:placeholder-shown) + label::before {
  right: 19%;
  top: 13%;
}
.p-paginator-page.p-paginator-element.p-link.p-paginator-page-end {
  margin-left: 5px;
}
.dashboard-table .table td .table-avatar a {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 6px;
  color: #111;
}
button.p-paginator-page.p-paginator-element.p-link {
line-height: normal !important;
height: auto;
}
div.p-paginator {
  justify-content: flex-end;
}
button.p-paginator-element:hover {
  background: none;
}
.p-paginator-page.p-paginator-element:hover {
background-color: #127384;
}
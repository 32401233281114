/*-----------------
    11. Home
-----------------------*/

.section {
    position: $__relative;
    @include margin-padding(null, 80px 0);
    @include respond-below(custom991) {
        @include margin-padding(null, 50px 0);
    } 
}
.banner-slider {
    position: $__relative;
    background-image: url(../../../../public/assets/img/banner.png);
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    @include margin-padding(null, 50px 100px 50px 100px);
    @include respond-below(custom991) {
        background: $__light__vampire;
        @include margin-padding(null, 70px 0 70px);
    }   
    @include respond-below(custom479) {
        .hero-book-btn{
            width: 100%!important;
        }
        .hero-section-block{
            margin-bottom: -55px!important
        }
        @include margin-padding(null, 50px 0 50px);

        display: block;
    }    
    .home-banner {
        h1 {
            letter-spacing: -0.02em;
            @include margin-padding(0 0 10px, null);
            span {
                color: $__text-warning__light
            }
        }
        .banner-imgs {
           
                @include respond-below(custom991) {
                    display: none;
                } 
            img {
                position: $__relative;
                z-index: 99;
                @include respond-below(custom991) {
                    display: none;
                } 
            }
        }
        .explore-text {
            color: $__gray;
            @include margin-padding(null, 10px 20px);
            background: $__white;
            border-radius: 70px;
            display: inline-block;
            @include margin-padding(0 0 15px, null);
                @include respond-below(custom991) {
                    @include margin-padding(null, 10px 15px);
                } 
            span {
                color: $__golden__yellow;
                i {
                    font-weight: $__semibold;
                }
            }
        }
    }
    p {
        @include margin-padding(0 0 30px, null);
        font-size: $__font__size__18;
            @include respond-below(custom991) {
                font-size: $__font__size__14;
            } 
    }    
}
.search-box-banner {
    background: $__white;
    box-shadow: 0px 4px 24px rgb(225 225 225 / 25%);
    border-radius: 10px;
    @include margin-padding(null, 25px 40px 32px 40px);        
    z-index: 1;
    position: $__relative;
    margin: -35px auto auto;
        @include respond-below(custom991) {
            padding: 15px 15px 0;
            max-width: 100%;
        }
        @include respond-below(custom767) {
            padding: 20px;
            max-width: 100%;
        }
        @include respond-below(custom479) {
            margin-top: 3rem;
        }
    ul {
        list-style: none;
        margin: 0 -10px;
        padding: 0;
        outline: none;
        @extend %equal-heights;
    }
    .group-img {
        position: $__relative;
        i {
            position: $__absolute;
            top: 15px;
            left: 15px;
            @include respond-below(custom991) { 
                top: 13px;
            }
        }
        input, select {
            @include margin-padding(null, 14px 13px 13px 38px);
                @include respond-below(custom991) {
                    @include margin-padding(null, 10px 10px 10px 35px);
                }
        }
    }
    .input-block {
        @include margin-padding(0, 0 10px);
            @include respond-below(custom991) {
               
            } 
        label {
            @include margin-padding(0 0 5px, null);
            font-weight: $__bold;
            font-size: 15px;
            color: $__gray;
            @include respond-below(custom991){
                font-size:$__font__size__14;
            }
        }

        .phone-input-style {
            background: #FCFBFB;
            border: 1px solid #F4F4F4;
            box-shadow: none;
            border-radius: 5px;
            padding-left: 13px;
            font-size: 14px;
            font-weight: 400;
            color: #201F1D;

            input {
                border: none;
                background: #FCFBFB;
                padding: 13px;
            }
        }
    }
    .search-btn {
        .search-button {
            background-color: $__primarycolor;
            border: 1px solid $__primarycolor;                
            box-shadow: inset 0 0 0 $__white;                
            color: $__white;      
            width: $__full__width;       
            @include margin-padding(null, 10px 12px);           
            @extend %animation-effect;
            i {
                margin-right: 8px;
            }
                @include respond-below(custom767) {
                    width: 100%;
                } 
                @include respond-below(custom991) {
                    @include margin-padding(null, 8px 12px);
                }               
            &:hover {
                background-color: $__white;
                border: 1px solid #201F1D;
                box-shadow: inset 0 70px 0 0 $__white;
                color: #201F1D;
                @extend %animation-effect;
            }
        }
    }
    .column-group-last {
        -ms-flex: 13%;
        flex: 13%;
        max-width: 13%;
            @include respond-below(custom991) {
                -ms-flex: 50%;
                flex: 50%;
                max-width: 50%;
            }            
            @include respond-below(custom767) {
                -ms-flex: 100%;
                flex: 100%;
                max-width: 100%;
            }         
        &:last-child {
            padding-right: 0;
            margin-top: 1rem;
        }
        .search-btn {
            margin-top: 30px;
            @include respond-below(custom991) {
                @include margin-padding(15px 0 0, null);
            }
            @include respond-below(custom767) {
                @include margin-padding(0, null);
            }
        }
    }
    .column-group-main {
        -ms-flex: 33.33%;
        padding: 10px 0px;
        flex: 33.33%;
        max-width: 33.33%;
            @include respond-below(custom991) {
                -ms-flex: 50%;
                flex: 50%;
                max-width: 50%;
                margin-bottom: 15px;
            }            
            @include respond-below(custom767) {
                -ms-flex: 100%;
                flex: 100%;
                max-width: 100%;
                margin-bottom: 0;
            } 
        &:last-child {
            padding-right: 0;
        }
    }
    .input-block-wrapp {
        @extend %display-flex-center;
        @include respond-below(custom767) {
            display: block;
        }
    }
}  
.section-heading {
    text-align: center;
    @include margin-padding(0 0 30px, null); 
        @include respond-below(custom991) {
            @include margin-padding(0 0 30px, null);
        }
    h2 {
        position: $__relative;
        @include margin-padding(0 0 30px, null);
            @include respond-below(custom991) {
                @include margin-padding(0 0 20px, null);
            }
        &:after {
            content: "";
            position: $__absolute;
            background-image: url(../../../../public/assets/img/bg/title-head.png);            
            background-position: center;
            background-repeat: no-repeat;
            left: 0;
            height: 11px;
            padding-top: 16px;
            right: 0;
            bottom: -22px;
            z-index: 99;            
            @include respond-below(custom991) {
                bottom: -17px;
            }
        }
    }    
    p {
        max-width: 439px;
        margin: auto;
        padding-top: 10px;
        font-size: $__font__size__16;
        @include respond-below(custom991){
            font-size: $__font__size__16;
        }
    }
}
.services {  
    padding: 80px 0 56px;
    @include respond-below(custom991){
        padding: 50px 0 36px;
    }
    .service-right {
        position: absolute;
        top: 0;
        right: 0;
            @include respond-below(custom991) {
                display: none;
            }
    }
    .services-group {        
        @include margin-padding(0 0 24px, null);
        text-align: center;
        position: $__relative;
        .services-icon {
            @extend %inline-flex;
            border-radius: 50px;
            @include margin-padding(0 0 35px, 10px);
            background: $__white;
            position: $__relative;
            z-index: 99;
            @include respond-below(custom991) {
                @include margin-padding(null, 8px);
            }
            .icon-img {            
                border-radius: 50px;
                @extend %inline-flex;
                width: 72px;
                height: 72px;
                @include margin-padding(null, 10px);
                    @include respond-below(custom991) {
                        @include margin-padding(null, 8px);
                    }
            }
        }
        .services-content {
            h3 {
                @include margin-padding(0 0 10px, null);
            }
        }
    }
    .services-one {
        border-bottom: 2px solid $__dark__blues;
            @include respond-below(custom767) {
                border-bottom: 0;
            }
    }
    .services-two {
        border-bottom: 2px solid $__warningcolor;
            @include respond-below(custom767) {
                border-bottom: 0;
            }
    }
    .services-three {
        border-bottom: 2px solid $__goose-gray;
            @include respond-below(custom767) {
                border-bottom: 0;
            }
        &:before {
            border: 0;
            width: 0;
        }
    }
}
.popular-car-type{
    background-color: $__white;
}
.popular-services {
    background: $__white;
    .listing-tabs-group {
        @extend %equal-heights;
        @extend %justify-content-center;
        @include margin-padding(0 0 30px, null);
            @include respond-below(custom991) {
                @include margin-padding(0 0 30px, null);
            }
        ul {
            display: flex;
            align-items: center;
            justify-content: center;
            @include respond-below(custom767) {
                display: flex;
                align-items: center;
                justify-content: center;
                li {
                    a {
                        min-width: 120px
                    }
                }
            }
            li {
                .carttypestabs {
                    background: $__white;
                    border: 1px solid $__light__gooses;
                    @extend %display-flex-center;
                    @extend %justify-content-center;
                    @include margin-padding(null, 10px 23px);
                    text-transform: capitalize;
                    font-weight: $__regular;
                    font-size: $__font__size__16;
                    color: $__gray;
                    border-radius: 6px;
                    @extend %animation-effect;
                        @include respond-below(custom991) {   
                            @include margin-padding(null, 8px 18px);
                            font-size: $__font__size__14;
                            min-width: 120px;
                        }
                    &.active {
                        background: $__dark__blues;
                        color: $__white;
                        img {
                            @extend %animation-effect;
                        }
                    }
                    &:hover {
                        cursor: pointer;
                        background: $__dark__blues;
                        color: $__white;
                        @extend %animation-effect;
                        img {
                            @extend %animation-effect;
                        }
                    }
                    span {
                        @include margin-padding(0 10px 0 0, 10px);
                        line-height: 0;
                        background: #FCFCFC;
                        border: 1px solid #F2F7F6;
                        border-radius: 6px;
                        width: 35px;
                        height: 35px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        @extend %animation-effect;
                        @include respond-below(custom991) {   
                            @include margin-padding(null, 8px);
                            width: 30px;
                            height: 30px;
                        }
                    }
                    img {
                        @extend %animation-effect;
                    }
                }
            }
        }
    }
    .owl-carousel {
        .owl-dots.disabled {
            display: block;
        }
        .owl-nav.disabled {
            display: block;
        }
        .owl-prev {
            position: absolute;
            top: 50%;
            left: 0;  
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); 
            -webkit-transition: 500ms all ease;
            -o-transition: 500ms all ease;
            transition: 500ms all ease;
            -moz-transition: 500ms all ease;
            -ms-transition: 500ms all ease;
            &:hover {
                i {
                    background: $__primarycolor !important;
                    color: $__white;
                    -webkit-transition: 500ms all ease;
                    -o-transition: 500ms all ease;
                    transition: 500ms all ease;
                    -moz-transition: 500ms all ease;
                    -ms-transition: 500ms all ease;
                                }
            }      
            i {
                height: 55px;
                width: 55px;
                background: $__white !important;
                color: $__gray;
                margin: auto 0;
                border-radius: 50%;
                @extend %display-flex-center;
                @extend %justify-content-center;
                -webkit-transition: 500ms all ease;
                -o-transition: 500ms all ease;
                transition: 500ms all ease;
                -moz-transition: 500ms all ease;
                -ms-transition: 500ms all ease;
            }
        }
        .owl-next {
            position: absolute;
            top: 50%;
            right: 0;
            -ms-transform: translate(-50%, -50%);
            transform: translate(50%, -50%);
            -webkit-transition: 500ms all ease;
            -o-transition: 500ms all ease;
            transition: 500ms all ease;
            -moz-transition: 500ms all ease;
            -ms-transition: 500ms all ease;
            &:hover {
                i {
                    background: $__primarycolor !important;
                    color: $__white;
                    -webkit-transition: 500ms all ease;
                    -o-transition: 500ms all ease;
                    transition: 500ms all ease;
                    -moz-transition: 500ms all ease;
                    -ms-transition: 500ms all ease;
                }
            }       
            i {
                height: 55px;
                width: 55px;
                background: $__white !important;
                color: $__gray;
                margin: auto 0;
                border-radius: 50%;
                @extend %display-flex-center;
                @extend %justify-content-center;
                -webkit-transition: 500ms all ease;
                -o-transition: 500ms all ease;
                transition: 500ms all ease;
                -moz-transition: 500ms all ease;
                -ms-transition: 500ms all ease;
            }
        }
        
    }
}
.popular-explore {
    @include margin-padding(null, 80px 0 56px 0);
    @include respond-below(custom991) {
        padding: 50px 0 26px 0;
    }
}
.listing-item {
    position: $__relative;
    background: $__white;
    border-radius: 10px;
    @include margin-padding(0 0 24px 0, 15px);
        @include respond-below(custom991) {
            @include margin-padding(null, 15px);
        }
    .listing-img {
        position: $__relative;
        overflow: hidden;
        border-radius: 10px;
        @include margin-padding(0 0 10px, null);
        img {
            border-radius: 10px;
            width: 100%;
            transform: translateZ(0);
            transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
        }
        .fav-item {
            position: $__absolute;
            left: 0;
            top: 0;
            width: 100%;
            @include margin-padding(null, 15px);
            z-index: 1;
            @extend %display-flex-center-between;
            font-size: $__font__size__14;
            @extend %animation-effect;
                @include respond-below(custom991) {
                    font-size: $__font__size__13;
                }
            .featured-text {
                background: $__white;
                border-radius: 5px;
                min-width: 75px;
                @include margin-padding(null, 5px 15px);
                font-size: $__font__size__14;
                color: $__sandstone;
                @extend %display-flex-center;
                @extend %justify-content-center;
                    @include respond-below(custom991) {
                        font-size: $__font__size__12;
                    }
            }
            .fav-icon {
                width: 26px;
                height: 26px;
                @extend %display-flex-center;
                @extend %justify-content-center;
                border-radius: 50%;
                color: $__white;
                border: 1px solid $__white;
            }
        }
        .fav-icon.selected {
            background-color: $__primarycolor;
            border: 1px solid $__primarycolor !important;
            color: #fff;
        }
    }
    &:hover {
        .listing-img {
            img {
                -webkit-transform: scale(1.15);
                -moz-transform: scale(1.15);
                transform: scale(1.15);
            }
        }
        .listing-title {
            a {                
                color: $__dark__blues !important;
            }
        }
    }
    .listing-content {
        margin-top: 15px;
        .listing-features {
            position: $__relative;
            border-bottom: 1px solid $__light__gooses;
            @include margin-padding(0 0 15px, 0 0 15px);
            .author-img {
                position: $__absolute;
                top: -35px;
                z-index: 2;
                right: 15px;
                border-radius: 50px;
                img {
                    width: 35px;
                    height: 35px;
                    @extend %inline-flex;
                    border-radius: 50px;
                    border: 2px solid $__white;
                }
            }
            .listing-title {
                margin-bottom: 5px;
                text-align: left;
                font-size: $__font__size__20;
                a {
                    font-weight: $__semibold;
                    color: $__light__smokey-grays;
                    @extend %inline-flex;
                    @include respond-below(custom991) {
                        font-size: $__font__size__18;
                    }
                }
            }
            h6 {
                font-size: $__font__size__15;
                color: $__grey;
                font-weight: $__regular;
            }
            .list-rating {
                padding-top: 7px;
                text-align: left;
                
                i {
                    color: $__warningcolor;
                    font-size: $__font__size__14;
                        @include respond-below(custom991) {
                            font-size: $__font__size__12;
                        }
                }
                
                
                span {
                    border-radius:5px;
                    background: #F5F5F5;
                    padding: 5px 5px 5px 5px;
                    font-weight: $__medium;
                    color: $__sandstone;
                    @include margin-padding(8px 5px 8px 0px,null) 
}
            }
            
        }
        .listing-details-group {
            @include margin-padding(0 0 15px, null);            
            ul {
                @extend %flex-align-center;                
                @include margin-padding(0 0 10px, null);                                
                &:last-child {
                    @include margin-padding(0, null);
                }
                li {
                    @extend %display-flex-center;
                    -ms-flex: 33.33%;
                    flex: 33.33%;
                    max-width: 33.33%;
                    span {
                        img {
                            line-height: 0;
                            width: 16px;
                            display: flex;
                            justify-content: center;
                        }
                    }
                    p {
                        color: $__grey;
                        @include margin-padding(0 0 0 8px, null);
                        @include respond-below(custom991) {
                            font-size: $__font__size__13;
                        }
                    }
                }
            }
        }
        .listing-location-details {
            background: $__light__vampire;
            @extend %display-flex-center-between;
            @include margin-padding(null, 10px 8px);
            @include margin-padding(0 0 15px, null);
            .listing-price {
                @include respond-below(custom991) {
                    font-size: $__font__size__13;
                }
                span {
                    margin-right: 7px;
                    line-height: 0;
                }
            }
            .listing-price {
                h6 {
                    font-weight: $__bold;
                    font-size: $__font__size__24;
                    color: $__dangered;
                    @extend %display-flex-center;
                    @include respond-below(custom991) {
                        font-size: $__font__size__20;
                    }                    
                    span {
                        color: $__note__light;
                        font-size: $__font__size__14;
                        font-weight: $__regular;
                        margin-left: 7px;
                        @include respond-below(custom991) {
                            font-size: $__font__size__12;
                        }
                    }
                }
            }
        }
        .listing-button {
            .btn-order {
                @extend %display-flex-center;
                @extend %justify-content-center;
                background: $__goose-gray;
                position: $__relative;
                border-radius: 5px;
                font-weight: $__semibold;
                font-size: $__font__size__16;
                color: $__white;
                text-align: $__center;
                padding: 10px;
                width: 100%;
                @include respond-below(custom991) {
                    font-size: $__font__size__14;
                }
                span {
                    line-height: 0;
                    i {
                        line-height: 0;
                    }
                }
            }
        }
    }
    &:hover {
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
        .btn-order {
            background-color: $__dark__blues !important;
            color: $__white;
            border-radius: 5px;
        }
        .fav-icon {
            background: $__dark__blues;
            border: 1px solid $__dark__blues !important;
            -webkit-transition: all 0.5s;
            -moz-transition: all 0.5s;
            -o-transition: all 0.5s;
            transition: all 0.5s;
            i {                    
                color: $__white;
                @extend %animation-effect;
            }
        }
    }
}
// Most Popular Cartypes
.slick-prev:before{
    color: $__primarycolor;
}
.slick-next:before{
    color: $__primarycolor;
}
.popular-slider-group {
    .slick-dots{
        margin-bottom: -12px;
    }
    @include margin-padding(0 0 50px, null);  
    .listing-owl-item {
        .listing-owl-group {
            @include margin-padding(null, 30px);
            background: $__light__hash;
            box-shadow: 0px 4px 24px rgba(225, 225, 225, 0.25);
            border-radius: 6px;
            text-align: $__center;
            .listing-owl-img 
{
                @include margin-padding(0 0 20px, null);
                img {
                    width: 60%;
                    height: auto;
                    text-align: $__center;
                    margin: $__auto;                
                    -webkit-transition: 500ms all ease;
                    -o-transition: 500ms all ease;
                    transition: 500ms all ease;
                    -moz-transition: 500ms all ease;
                    -ms-transition: 500ms all ease;
                }
            }        
            h6 {
                @include margin-padding(0 0 5px, null);            
                -webkit-transition: 500ms all ease;
                -o-transition: 500ms all ease;
                transition: 500ms all ease;
                -moz-transition: 500ms all ease;
                -ms-transition: 500ms all ease;
            }
            p {
                font-size: $__font__size__14;
                color: $__sandstone;            
                -webkit-transition: 500ms all ease;
                -o-transition: 500ms all ease;
                transition: 500ms all ease;
                -moz-transition: 500ms all ease;
                -ms-transition: 500ms all ease;
            }
            
            // &:hover {
            //     background-color: $__note__light;
            //     -webkit-transition: 500ms all ease;
            //     -o-transition: 500ms all ease;
            //     transition: 500ms all ease;
            //     -moz-transition: 500ms all ease;
            //     -ms-transition: 500ms all ease;
            //     .listing-owl-img {
            //         img {
            //             filter: invert(1);
            //         }  
            //     }
            //     h6 {
            //         color: $__white;
            //     }
            //     p {
            //         color: $__white;
            //     }
            // }
        }
    }
    .owl-carousel {
        .owl-dots.disabled {
            display: block;
        }
        .owl-nav.disabled {
            display: block;
        }
        .owl-prev {
            position: absolute;
            top: 50%;
            left: 0;  
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); 
            -webkit-transition: all 0.7s;
            -moz-transition: all 0.7s;
            -o-transition: all 0.7s;
            transition: all 0.7s;
            @include respond-below(custom1199) {
                left: 7px;
            }
            &:hover {
                i {
                    background: $__primarycolor !important;
                    color: $__white;
                    -webkit-transition: 500ms all ease;
                    -o-transition: 500ms all ease;
                    transition: 500ms all ease;
                    -moz-transition: 500ms all ease;
                    -ms-transition: 500ms all ease;
                }
            }      
            i {
                height: 55px;
                width: 55px;
                background: $__white !important;
                margin: auto 0;
                border-radius: 50%;
                @extend %display-flex-center;
                @extend %justify-content-center;
                -webkit-transition: all 0.7s;
                -moz-transition: all 0.7s;
                -o-transition: all 0.7s;
                transition: all 0.7s;
                @include respond-below(custom1199) {
                    height: 35px;
                    width: 35px;
                    font-size: $__font__size__12;
                }
            }
        }
        .owl-next {
            position: absolute;
            top: 50%;
            right: 0;
            -ms-transform: translate(-50%, -50%);
            transform: translate(50%, -50%);
            -webkit-transition: all 0.7s;
            -moz-transition: all 0.7s;
            -o-transition: all 0.7s;
            transition: all 0.7s;
                @include respond-below(custom1199) {
                    right: 7px;
                }
            &:hover {
                i {
                    background: $__primarycolor !important;
                    color: $__white;
                    -webkit-transition: 500ms all ease;
                    -o-transition: 500ms all ease;
                    transition: 500ms all ease;
                    -moz-transition: 500ms all ease;
                    -ms-transition: 500ms all ease;
                }
            }       
            i {
                height: 55px;
                width: 55px;
                background: $__white !important;
                margin: auto 0;
                border-radius: 50%;
                @extend %display-flex-center;
                @extend %justify-content-center;
                -webkit-transition: all 0.7s;
                -moz-transition: all 0.7s;
                -o-transition: all 0.7s;
                transition: all 0.7s;
                    @include respond-below(custom1199) {
                        height: 35px;
                        width: 35px;
                        font-size: $__font__size__12;
                    }
            }
        }
        
    }
    
}

// Facts By The Numbers
.popular-routes{
    h6{
        font-weight: bold;
    }
    .listing-owl-img{
        padding-top:10px;
        img{
            width: auto!important;
        }
      
    }
    p{
        padding-bottom: 10px;
    }
    .listing-owl-group{
        padding: 0px!important;
    }
    
        .slick-active{
            margin: 0 10px!important;
        }
    
    
}
.facts-number {
    background: $__goose-gray;
    @include respond-below(custom991) {
        padding-bottom: 35px;
    }
    .facts-left {
        img {
            position: $__absolute;
            // left: -80px;
            top: 9rem;
            @include respond-below(custom991) {
                display: none;
            } 
        }
    }
    .facts-right {
        img {
            position: $__absolute;
            right: 60px;
            top: 30px;
            @include respond-below(custom991) {
                display: none;
            } 
        }
    }
    .count-group {
        position: $__relative;
        width: 100%;
        display: $__flex;
        top: 0;
        background-image: url(../../../../public/assets/img/bg/count-bg.jpg);
        background-repeat: no-repeat;
        background-position: right;
        background-size: cover;
        border-radius: 10px;
        @include margin-padding(null, 20px);
        -webkit-transition: 500ms all ease;
        -o-transition: 500ms all ease;
        transition: 500ms all ease;
        -moz-transition: 500ms all ease;
        -ms-transition: 500ms all ease;
            @include respond-below(custom991) {
                @include margin-padding(0 0 15px, 15px);
            }
        &:hover {
            top: -10px;
            .count-img {
                background: $__dark__blues;
            } 
        }
        .count-img {
            @include margin-padding(null, 24px);
            background: $__goose-gray;
            border-radius: 10px;
            display: $__flex;
            align-items: $__center; 
            @include margin-padding(0 20px 0 0, null);
            -webkit-transition: 500ms all ease;
            -o-transition: 500ms all ease;
            transition: 500ms all ease;
            -moz-transition: 500ms all ease;
            -ms-transition: 500ms all ease;
                @include respond-below(custom991) {
                    @include margin-padding(null, 15px);
                }
        }        
        .count-content {
            h4 {
                font-weight: $__highbold;
                @include margin-padding(0 0 7px, null);
            }
        }
    }
}

// Recommended Car Rental deals

.rental-car-item {
    .fav-item-rental {
        position: $__absolute;
        top: -25px;
        z-index: 2;
        right: 15px;
        border-radius: 50px;
        .featured-text {
            background: $__primarycolor;
            border-radius: 3px;
            @include margin-padding(null, 6px 10px);
            color: $__white;
            font-weight: $__medium;
            font-size: $__font__size__15;
                @include respond-below(custom991) { 
                    font-size: $__font__size__14;
                }
        }
    }    
    .listing-title {        
        @include margin-padding(5px 0 0, null);
        a {
            font-weight: $__bold;
            color: $__light__smokey-grays;
        }
        h6 {
            font-size: $__font__size__16;
            color: $__gray;            
            @include margin-padding(5px 0 0, null);
            @include respond-below(custom991) {
                font-size: $__font__size__15;
            }
            span {
                color: $__gray;
                font-weight: $__regular;
            }
        }
    }
}

// Why Choose Us

.why-choose {
    .choose-left {
        position: $__absolute;
        left: 0;
        top: 10px;
        @include respond-below(custom991) {
            display: none;
        } 
    }
    .card {          
        -webkit-transition: 500ms all ease;
        -o-transition: 500ms all ease;
        transition: 500ms all ease;
        -moz-transition: 500ms all ease;
        -ms-transition: 500ms all ease;      
        .card-body {
            .choose-img {
                @include margin-padding(0 0 20px, 20px);
                border-radius: 10px;
                @extend %flex-align-center;                
                -webkit-transition: 500ms all ease;
                -o-transition: 500ms all ease;
                transition: 500ms all ease;
                -moz-transition: 500ms all ease;
                -ms-transition: 500ms all ease;
                    @include respond-below(custom991) { 
                        @include margin-padding(null, 15px);
                    }
                img {                    
                    -webkit-transition: 500ms all ease;
                    -o-transition: 500ms all ease;
                    transition: 500ms all ease;
                    -moz-transition: 500ms all ease;
                    -ms-transition: 500ms all ease;
                }
            }
            .choose-black {            
                background: $__goose-gray;
                
            }        
            .choose-secondary {            
                background: $__dark__blues;
            }
            .choose-primary {            
                background: $__primarycolor;
            }
            .choose-content {
                text-align: $__center;
                h4 {
                    font-size: $__font__size__24;
                    font-weight: $__bold;
                    @include margin-padding(0 0 20px 0, null);
                    -webkit-transition: 500ms all ease;
                    -o-transition: 500ms all ease;
                    transition: 500ms all ease;
                    -moz-transition: 500ms all ease;
                    -ms-transition: 500ms all ease;
                        @include respond-below(custom991) { 
                            font-size: $__font__size__22;  
                            @include margin-padding(0 0 15px 0, null); 
                        }
                }
                p {
                    -webkit-transition: 500ms all ease;
                    -o-transition: 500ms all ease;
                    transition: 500ms all ease;
                    -moz-transition: 500ms all ease;
                    -ms-transition: 500ms all ease;
                    max-width: 265px;
                    margin: $__auto;
                }
            }
        }        
        &:hover {
            background: #000;
            .choose-black {            
                background: $__white;
            }        
            .choose-secondary {            
                background: $__white;
            }
            .choose-primary {            
                background: $__white;
            }
            .choose-content {
                h4 {
                    color: $__white;
                }
                p {
                    color: $__white;
                }
            }
            .choose-img {
                position: $__relative;
                background-image: url(../../../../public/assets/img/bg/count-bg.jpg);
                background-repeat: no-repeat;
                background-position: right;
                background-size: cover;
                img {
                    filter: invert(1);
                }
            }
            
        }
    }
}

// What People say about us

.about-testimonial {
    background-image: url(../../../../public/assets/img/bg/about-testimonial.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; 
    @include respond-below(custom991) {
        background: $__goose-gray;
    }    
	.owl-dots {
        text-align: center;
		.owl-dot {
            @include margin-padding(0 8px 0 0, null);
            span {
                width: 8px;
                height: 8px;
                margin: 0;
                background: $__blue__light;
                border-radius: 50%;
                display: block;
                -webkit-transition: all 0.4s ease;
                -moz-transition: all 0.4s ease;
                transition: all 0.4s ease;
            }
            &.active {                
                span {
                    background: $__primarycolor;
                    width: 46px;
                    height: 8px;
                    border-radius: 40px;
                }
            } 
		}
	}       
}

// User Friendly App Available

.user-app-group {
    background: $__gray__dark;
    position: $__relative;
    border-radius: 10px;
    @include margin-padding(56px 0 0, 80px);
        @include respond-below(custom991) {
            @include margin-padding(26px 0 0, 40px 40px 25px 40px);
        }
    .userapp-heading {
        h2 {
            font-weight: $__highbold;
            font-size: $__font__size__52;
            color: $__white;
            @include margin-padding(0 0 40px, null);
                @include respond-below(custom991) {
                    font-size: $__font__size__32;
                }
        }
        p {
            font-size: $__font__size__18;
            color: $__white;
            @include margin-padding(0 0 37px, null);
                @include respond-below(custom991) {
                    font-size: $__font__size__16;
                    @include margin-padding(0 0 27px, null);
                }
        }
        .download-btn {
            @extend %display-flex-center;
            @include respond-below(custom767) {
                display: block;
            }
            .app-avilable {
                margin: 0 7px 0 0;
                @include respond-below(custom991) {
                    margin: 0 7px 15px 0;
                }
            }
        }
    }
    .app-left {
        position: $__absolute;
        left: 5%;
        bottom: 5%;
        @include respond-below(custom991) {
            display: none;
        }
    }
    .app-right {
        position: $__absolute;
        right: 30%;
        top: 10%;
        @include respond-below(custom991) {
            display: none;
        }
    }
}
.bg-secondary {
    background: $__dark__blues !important;
}
.border-secondary {
    border: 2px dashed $__dark__blues;
}
.bg-warning {
    background: $__warningcolor !important;
}
.border-warning {
    border: 2px dashed $__warningcolor;
}
.bg-dark {
    background: $__goose-gray;
}
.border-dark {
    border: 2px dashed $__goose-gray;
}

.bootstrap-datetimepicker-widget {
	table {
        width: 100%;
        .date-widge {            
            td {
                padding: 20px;
                width: 100%;
            }
        }
		td.active {
			background-color: $__primarycolor;
			text-shadow: unset;
			&:hover {
				background-color: $__primarycolor;
				text-shadow: unset;
			}
		}
		th {
			font-size: 14px;
			font-weight: 400;
            padding: 12px;
            width: 100%;
		}
		td.day {
			font-size: 14px;
			font-weight: 400;
            padding: 10px;
		}
	}
}

.bootstrap-datetimepicker-widget {
    .timepicker-hour {
        table {
            td {
                padding: 20px;
                width: 100%;
            }
            th {
                font-size: 14px;
                font-weight: 400;
                padding: 12px;
                width: 100%;
            }
        }
    }
    .timepicker-minute {
        table {
            td {
                padding: 20px;
                width: 100%;
            }
            th {
                font-size: 14px;
                font-weight: 400;
                padding: 15px;
                width: 100%;
            }
        }
    }
    
}

.primary-color{
    color: $__primarycolor;
}
.react-datepicker-wrapper{
    width: 100%;
}
.search-box-banner .date-widge{
    width: 100%;
}
.PhoneInput{
    width: 100%;
}
.modal-backdrop.show {
    pointer-events: none !important; /* Disable pointer events */
  }
  .search-box-banner .date-widget{
    width: 100%;
  }
  .whatsapp-section{
    @include respond-below(custom991) {
        text-align: center!important;
    }

  }
  .whatsapp-section-image{
    @include respond-below(custom991) {
        width: 100%;
    }
  }
  .centered-text {
    p{
        margin: 0px!important;
    }
    position: absolute;
    line-height: normal;
    font-weight: bold;
    top: 67%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
  }
  
/*-----------------
    5. Buttons
-----------------------*/

.btn {
    position: $__relative;
    text-transform: initial;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    &:hover {
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
    }
}
.btn-view {
    font-weight: $__medium;
    font-size: $__font__size__16;
    color: $__white;
    border: 2px solid $__primarycolor;
    background-color: $__primarycolor;
    box-shadow: inset 0 0 0 0 $__white;
    border-radius: 5px;
    line-height: 0;
    width: 25%;
    @include margin-padding(0, 11px 22px);
    &:hover {
        background-color: $__primarycolor;
        border: 2px solid $__warningcolor;
        color: $__white;
        box-shadow: inset 0 50px 0 0 $__warningcolor;
    }
    @include respond-below(custom991){
        @include margin-padding(0, 8px 12px);
		font-size:$__font__size__14;
	}
    
}

.btn-white {
    background-color: $__white;
    border-color: $__light__grayish;
    color: $__primarycolor;
    box-shadow: inset 0 0 0 0 $__white;
    &:hover {
        background-color: $__white;
        border-color: $__white;
        color: $__white;
        box-shadow: inset 0 50px 0 0 $__primarycolor;
    }
}
.btn-white-outline {
    background-color: $__white;
    border-color: $__light__grayish;
    color: $__grayish__blue;
    &:hover {
        background-color: $__white;
        border-color: $__light__grayish;
        color: $__grayish__blue;
    }
}
.btn-primary {
    background-color: $__primarycolor;
    border: 1px solid $__primarycolor;
    box-shadow: inset 0 0 0 0 $__white;
    &:hover {
        background-color: $__white;
        border-color: $__primarycolor;
        color: $__primarycolor;
        box-shadow: inset 0 50px 0 0 $__white;
    }
}
.btn-subscribe {
    background-color: $__primarycolor;
    border: 1px solid $__primarycolor;
    box-shadow: inset 0 0 0 0 $__white;
    &:hover {
        i {
            color: $__primarycolor !important;
            -webkit-transition: 0.5s;
            -ms-transition: 0.5s;
            transition: 0.5s;
        }
        background-color: $__white;
        border-color: $__primarycolor;
        color: $__primarycolor;
        box-shadow: inset 0 50px 0 0 $__white;
    }
}
.submit-review {
    @include margin-padding(null, 12px 20px);
}
.btn-size {
    font-weight: $__semibold;
    font-size: $__font__size__16;
    @include margin-padding(null, 12px);
}
.btn-outline-light {
    color: $__white;
    background-color: $__goose-gray;
    border: 1px solid $__goose-gray;
    box-shadow: inset 0 0 0 0 $__white;
    &:hover {
        background-color: $__white;
        border-color: $__light__grayish;
        color: $__grayish__blue;
        box-shadow: inset 0 50px 0 0 $__white;
    }
}
.btn-gray {
    background-color: $__white__smoke;
    border: 1px solid $__white__smoke;
    box-shadow: inset 0 0 0 0 $__white;
    &:hover {
        background-color: $__white;
        border-color: $__white__smoke;
        color: $__gray15;
        box-shadow: inset 0 50px 0 0 $__white;
    }
}
.btn-gray-outline {
    background-color: $__white;
    border: 1px solid $__white__smoke;
    box-shadow: inset 0 0 0 0 $__white;
    &:hover {
        background-color: $__white__smoke;
        border-color: $__white;
        color: $__gray15;
        box-shadow: inset 0 50px 0 0 $__white;
    }
}
.btn-outline-primary {
    color: $__primarycolor;
    border-color: $__primarycolor;
    &:hover {
        background-color: $__primarycolor;
        border-color: $__primarycolor;
    }
}
.btn-check:focus+.btn-primary, 
.btn-primary:focus {
    box-shadow: $__none !important;
}
.btn{
    &:focus{
        box-shadow: $__none !important; 
    }
    &.btn-greys{
        background: $__white__smoke;
        color: $__secondarycolor;
        font-size: $__font__size__13;
        @include margin-padding(null, 8px 16px);
        font-weight: $__semibold;
    }
}
.cancel {
    background-color: $__white;
    border: 1px solid $__violet__dark;
    box-shadow: inset 0 0 0 0 $__violet__dark;
    color: $__violet__dark;
    &:hover {
        background-color: $__white;
        border-color: $__violet__dark;
        color: $__violet__dark;
        box-shadow: inset 0 50px 0 0 $__white;
    }
}
.contact-btn {
    background-color: $__goose-gray;
    border: 1px solid $__goose-gray;                
    box-shadow: inset 0 0 0 $__white;
    color: $__white;                
    border-radius: 5px;
    font-weight: $__bold;
    font-size: $__font__size__16;
    @include margin-padding(null, 12px 15px);
    text-align: center;
    @extend %animation-effect;
    &:hover {
        background-color: $__white;
        border: 1px solid $__goose-gray;
        box-shadow: inset 0 70px 0 0 $__white;
        color: $__goose-gray;
        @extend %animation-effect;
    }
}
.btn-secondary {
    background: $__gray;
    color: $__white;
}
.fa-facebook {
    background: #3080F8;
    color: $__white;
}  
.fa-twitter {
    background: #35B6FF;
    color: $__white;
} 
.fa-instagram {
    background: #FF2D20;
    color: $__white;
}
.fa-behance {
    background: #0066FF;
    color: $__white;
}
.fa-linkedin {
    background: #0066FF;
    color: $__white;
}
.btn-primary {
    &.btn {
        &:first-child {
            &:active {
                background: $__primarycolor;
                border-color: $__primarycolor;
            }
        }
    }
}
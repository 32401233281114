.inputsearch{
    height: 39px;
    border: 1px solid #F4F4F4;
    border-radius: 5px;
    min-width: 276px;
    background-color: #F5F5F5;
    padding: 10px 35px 10px 15px;
}
.hero-section-block{
    .banner-center-image{
        position: absolute;
        left: 42%;
        top: 15%;
    }
    .banner-right-image{
        position: absolute;
        right: 0%;
        top: -20%;
    }
    position: relative;
}
.text-right{
    text-align: right !important;
}
.pointer-event-none{
    pointer-events: none;
}
.max-width-100{
    max-width: 100%!important;
}
.w-60{
    width: 60%;
}
.slick-prev{
left: 2px !important;
z-index: 20;
position: absolute;
}
.slick-next {
    right: 0px!important;
}
.react-datepicker-popper{
    z-index: 20!important;
}
.package-detail{
    .feature-img
    { 
        img{
        width: 35px!important;
        height: auto;
    }

}
}